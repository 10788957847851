// import React, {useState} from 'react' // es7 => rfc
import PropTypes from 'prop-types'  // import proptypes for filter proptypes only

export default function Navbar(props) {  // props property : props
  // const [darkStyle, setDarkStyle] = useState({
  //   color:'black',
  //   backgroundColor:'white'
  // });
  // const [btnText, setBtnText] = useState("Dark")

  // const ToggleStyle = ()=>{
  //   if(darkStyle.color == 'black'){
  //     setDarkStyle({
  //       color:'white',
  //       backgroundColor:'black'
  //     })
  //     setBtnText ('Light')
  //   }else{
  //     setDarkStyle({
  //       color:'black',
  //       backgroundColor:'white'
  //     })
  //     setBtnText ('Dark')
  //   }
  // }

  return (
    <nav className={`navbar navbar-expand-lg navbar-${props.mode} bg-${props.mode}`} >
      <div className="container-fluid">
        <a className="navbar-brand" href="/"><img src="./../../cf_logo.png" width={'30px'}/> {props.title}</a>  {/*props prperty: props.title */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">Home</a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/about" data-bs-toggle="modal" data-bs-target="#exampleModal">{props.aboutName}</a>
            </li>

            {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li> */}
            {/* <li className="nav-item">
          <a className="nav-link disabled" aria-disabled="true">Disabled</a>
        </li> */}
          </ul>
          {/* <!-- Button trigger modal --> */}
          {/* <!-- Modal --> */}
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">About Text Convert Fly</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  Welcome to Text Convert Fly, your go-to online tool for manipulating and analyzing text effortlessly. Our website offers a range of features designed to streamline your text-related tasks, making it a versatile companion for writers, students, professionals, and anyone who works with textual content.
                  <h6>Key Features:</h6>
                  <h6>Count Text/Word Counter:</h6>
                  <p>This feature likely allows users to input text and get a count of words in that text. It may also include additional statistics such as character count, paragraph count, etc.</p>
                  <h6>Remove Extra Space from Words:</h6>
                  <p>This functionality is likely designed to clean up the input text by eliminating unnecessary spaces between words.</p>
                  <h6>Convert to Uppercase and Lowercase:</h6>
                  <p>Users can convert the case of the text, either transforming it to uppercase or lowercase.</p>
                  <h6>Calculate Time to Read:</h6>
                  <p>This feature calculates the estimated time it would take for an average reader to go through the given content. It could be based on factors like word count and reading speed.</p>
                 <h6>User-Friendly Interface:</h6>
                 <p>Our website boasts a clean and intuitive interface, ensuring a user-friendly experience. Whether you're a tech-savvy professional or a casual user, you'll find our tools easy to navigate.</p>
                 <h6>How to Use:</h6>
                 <p>Simply paste your text into the designated area or type directly on the platform. Select the desired function from the menu, and let our tool work its magic. Instantly view the results and download your modified text for immediate use.</p>
                 <h6>Privacy and Security:</h6>
                 <p>We prioritize the privacy and security of your data. All text processing is done on your device, ensuring that your sensitive information remains private.</p>
                 <h6>Access Anytime, Anywhere:</h6>
                 <p>Enjoy the convenience of accessing our text processing tools from any device with an internet connection. Whether you're on your desktop, tablet, or smartphone, Text Processing Hub is ready to assist you.</p>
                  <p>Overall, this website seems to be a comprehensive text utility that caters to users who need to analyze, format, and understand textual content more effectively. It could be useful for writers, students, editors, or anyone dealing with text-based information. The combination of features provides a well-rounded set of tools for text manipulation and analysis.</p>

                </div>
                <div class="modal-footer">
        {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> */}
        {/* <button type="button" class="btn btn-primary">Save changes</button> */}
        <p>develoved by <a href='https://kumarmanoj.com.np/'>Maargin</a></p>
      </div>
              </div>
            </div>
          </div>
          <button onClick={props.toggleMode} className={`btn btn-outline-secondary text-${props.mode === 'light' ? 'dark' : 'light'}`} type="submit"><i class="fa-solid fa-circle-half-stroke"></i></button>
          {/* <form className="d-flex" role="search">
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-success" type="submit">Search</button>
          </form> */}
        </div>
      </div>
    </nav>
  )
}

// Just filter Proptypes
Navbar.prototype = {
  title: PropTypes.string, //   title: PropTypes.string.isRequired,
  // aboutText: PropTypes.string
}


// this is default props if you haven't got any props then it will run
Navbar.defaultProps = {
  // title: 'Set Title here'
  aboutName: 'About'
};