// import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import React, { useState } from 'react';
import Alert from './components/Alert';

function App() {
  const [mode, setMode] = useState('light');  // for dark mode
  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    })
// for alert auto dismis after 1.5 sec
setTimeout(() => {
  setAlert(null);
}, 1500);

  }

  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark');
      document.body.style.backgroundColor = 'rgb(12 9 76)';
      showAlert("Dark Mode Enabled ", "Success");
      // document.title = 'convert.flyghar.com- Dark mode enabled';
      setInterval(()=>{
        document.title = 'convert.flygnar.com - Darkmode Emabled';
      },2000);
      setInterval(() => {
        document.title = 'Welcome to Flyghar.com Family';
      }, 1500);
    } else {
      setMode('light');
      document.body.style.backgroundColor = 'white';
      showAlert("Light Mode Enabled ", "Success");
      // document.title = 'convert.flyghar.com - Lightmode enabled'
    setInterval(() => {
      document.title = 'convert.flyghar.com - Light Mode Enabled';
    }, 2000);
    setInterval(() => {
      document.title = 'Welcome to Flyghar.com Family';
    }, 1500);

    }
  }
  // default blink title 
  setInterval(() => {
    document.title = 'convert.flyghar.com - Light Mode Enabled';
  }, 2000);
  setInterval(() => {
    document.title = 'Welcome to Flyghar.com Family';
  }, 1500);
  return (
    <>
      <Navbar title="Convert.flyghar.com" mode={mode} toggleMode={toggleMode} />  {/* props property Title convertors */}
      <Alert alert={alert} />
      {/* <div className="container"> */}
      <TextForm showAlert={showAlert} heading="Enter The text to Convert" mode={mode} />
      {/* </div>  */}
      
    </>
  );
}

export default App;