import React, { useState } from 'react'  // es7 shortcut : rfc  // hook : {useState}
// import React from 'react'  // es7 shortcut : rfc

export default function TextForm(props) {
    const handleUpClick = () => {
        // console.log("Uppercase was clicked" + text);
        let newText = text.toLocaleUpperCase(); // for uppercase convert
        setText(newText);
        // setText("You Have clicked on handleupclick")
        props.showAlert("Uppercase Converted", "success");
    }
    const handleSmClick = () => {
        // console.log("Uppercase was clicked" + text);
        let newText = text.toLowerCase(); // for Lowercase convert
        setText(newText);
        // setText("You Have clicked on handlesmclick")
        props.showAlert("LowerCase Converted", "success");
    }
    const handleClearClick = () => {
        // console.log("Uppercase was clicked" + text);
        let newText = ''; // for Lowercase convert
        setText(newText);
        // setText("You Have clicked on handlesmclick")
        props.showAlert("Text Cleared", "success");
    }
    const handleOnChange = (event) => {
        // console.log("Unchange");
        setText(event.target.value);  // for permission to type on box
    }

    const handleCopy = () => {
        var text = document.getElementById("preview");
        text.select();
        // text.setSelectionRange(0, 9999);
        navigator.clipboard.writeText(text.value);
        props.showAlert("Text Copied on Clipboard", "success");
    }
    const handleExtraSpace = () => {
        let newText = text.split(/[ ]+/);
        setText(newText.join(" "));
        props.showAlert("Extra Space Removed", "success");
    }

    const [text, setText] = useState('');  // hook line 
    return (
        <>
            <div className="row  container-fluid mt-3" >
                <div className="col-md-1">
                    
                </div>
                <div className="col-md-9 card shadow p-3" style={{ backgroundColor: props.mode === 'dark' ? 'gray' : 'white', color: props.mode === 'dark' ? 'white' : 'black' }}>
                    <div className=''> 
                    {/* style={{ color: props.mode === 'dark' ? 'white' : 'black' }}> */}
                        <div className="card">
                            <div className="card-header"  style={{ backgroundColor: props.mode === 'dark' ? 'gray' : 'white', color: props.mode === 'dark' ? 'white' : 'black' }}>
                                <h5>{props.heading}</h5>
                            </div>
                            <div className="card body">
                                <div className="">
                                    {/* <label forhtml="myBox" className="form-label">{props.heading}</label> */}
                                    <textarea className="form-control" placeholder='enter something here ..................' onChange={handleOnChange} id="myBox" value={text} style={{ backgroundColor: props.mode === 'dark' ? '#696969fd' : 'white', color: props.mode === 'dark' ? 'white' : 'black' }} rows="5"></textarea>
                                </div>
                            </div>
                        </div>


                        <button className="btn-sm btn btn-success  my-2" onClick={handleUpClick}>conver to Uppercase</button> &nbsp;
                        <button className="btn btn-sm btn-primary" onClick={handleSmClick}>conver to Lowercase</button> &nbsp;
                        <button className="btn btn-sm btn-info" onClick={handleExtraSpace}>Remove Extra Space</button> &nbsp;
                        {/* <button className="btn btn-sm btn-primary" onClick={handleCopy}>Copy</button> &nbsp; */}
                        <button className="btn btn-sm btn-danger" onClick={handleClearClick}>Clear</button>

                        {/* <h4 className='mt-3'>Your Text Summary</h4> */}
                        <div className='mb-2'>
                            <p><b>{text.split(" ").length}</b> Words &nbsp;&nbsp;&nbsp;&nbsp; <b>{text.length}</b> Characters  &nbsp;&nbsp;&nbsp;&nbsp;<b>{0.008 * text.split(" ").length}</b> Minuates to Read </p>
                        </div>
                        <div className="card">
                            <div className="card-header"  style={{ backgroundColor: props.mode === 'dark' ? 'gray' : 'white', color: props.mode === 'dark' ? 'white' : 'black' }}>
                                <p> <h5>Preview <button className="btn btn-sm btn-warning" onClick={handleCopy}> Copy</button></h5></p>
                            </div>
                            <div className="card body">
                                <textarea className="form-control" style={{ backgroundColor: props.mode === 'dark' ? '#696969fd' : 'white', color: props.mode === 'dark' ? 'white' : 'black' }} value={text.length > 0 ? text : ''} id="preview" rows="5"></textarea>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-2 text-center my-4">
                    <div className="card">
                        <div className="card-header ">
                            A. Comming Soon
                        </div>
                        <div className="card-body">
                            1. Img to Text Converter <br /><br />
                            2. Pdf to Doc Convert  
                        </div>
                    </div> <br/>
                    <div className="card">
                        <div className="card-header">
                            B. Comming Soon
                        </div>
                        <div className="card-body">
                            1. Nepali unicode Converter <br /><br />
                            2. Preeti Fonts Converter
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}